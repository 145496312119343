import React from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../../images/no-avatar.png";
import moment from "moment";
import { truncate } from "../../../utils/service";
import Verified from "../Verified/Verified";
// import { truncate } from "../../../../utils/service";

const ChatItem = ({ chat, onSurface }) => {
  // console.log(chat);
  const navigate = useNavigate();

  return (
    <div className="row nm">
      <div className="col s12">
        <div
          className={
            onSurface
              ? "card mb30 hand-cursor bg animation-appear"
              : "card mb30 hand-cursor animation-appear"
          }
          onClick={() => {
            navigate("/messages/" + chat.id);
          }}
        >
          <div className="card-content">
            <div
              className="row nm"
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="col s4 l2">
                <div className="row nm">
                  <div className="col s12">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "70px",
                        height: "70px",
                        position: "relative",
                        margin: "auto",
                      }}
                    >
                      <div
                        className={
                          chat.avatar_img_url
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" +
                            (chat.avatar_img_url
                              ? chat.avatar_img_url
                              : noImage) +
                            ")",
                          height: "70px",
                          width: "70px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s8 l10">
                <div className="row nm">
                  <div className="col s7 l10 m500 mb10">
                    {chat.user_name}
                    {chat.user_is_verified == 1 ? (
                      <Verified ml={5} mt={-3} />
                    ) : null}
                  </div>
                  <div className="col s5 l2 s300 right-align">
                    {moment(chat.timestamp).format("DD.MM.YYYY") ==
                    moment().format("DD.MM.YYYY")
                      ? moment(chat.timestamp).format("HH:mm")
                      : moment(chat.timestamp).format("DD.MM.YYYY")}
                  </div>
                  <div className="col s9">{truncate(chat.text, 40)}</div>
                  <div className="col s3 m500 mb10 right-align">
                    {chat.new_messages ? (
                      <span className="accent-counter">
                        {chat.new_messages}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
