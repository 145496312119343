import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RequestService from "../../../../api/RequestService";
import { Tabs, Tab, Button } from "react-materialize";
import EventList from "../../Event/EventList";
import ProjectList from "../../Project/ProjectList";
import { useFetching } from "../../../../hooks/useFetching";
import CircleLoader from "../../CircleLoader/CircleLoader";
import EventTiles from "../../Event/EventTiles";
import { useRef } from "react";
import { useObserver } from "../../../../hooks/useObserver";
import ProjectTiles from "../../Project/ProjectTiles";
import BookmarkList from "../../Bookmarks/BookmarkList/BookmarkList";

const UserFlow = ({ isSharingObjectsNeeded = 0 }) => {
  const [events, setEvents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [sharingObjects, setSharingObjects] = useState([]);
  const [involvedEventsData, setInvolvedEventsData] = useState(null);
  const [involvedProjectsData, setInvolvedProjectsData] = useState(null);
  const [sharingObjectsData, setSharingObjectsData] = useState(null);
  const params = useParams();
  const [eventPage, setEventPage] = useState(1);
  const [projectPage, setProjectPage] = useState(1);
  const [sharingObjectsPage, setSharingObjectsPage] = useState(1);
  const lastEventElement = useRef();
  const lastProjectElement = useRef();
  const lastSharingObjectsElement = useRef();

  const getUserInvolvedEvents = async (page = 1) => {
    if (involvedEventsData && page > involvedEventsData.total_pages) return;
    const response = (await RequestService.getInvolvedEvents(params.id, page))
      .data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setInvolvedEventsData(response.result);
    // console.log(events);
    // console.log(response.result.elements);
    // evts = [...evts, ...response.result.elements];
    setEvents([...events, ...response.result.elements]);
    // setEventPage(+response.result.page + 1);
    // console.log(evts);
    // setEvents(evts);
  };

  const getUserInvolvedProjects = async (page = 1) => {
    if (involvedProjectsData && page > involvedProjectsData.total_pages) return;
    const response = (await RequestService.getInvolvedProjects(params.id, page))
      .data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setInvolvedProjectsData(response.result);
    setProjects([...projects, ...response.result.elements]);
  };

  const getUserSharingObjects = async (page = 1) => {
    if (isSharingObjectsNeeded) {
      if (sharingObjectsData && page > sharingObjectsData.total_pages) return;
      const response = (await RequestService.getSharingObjects(page)).data;
      // console.log(response);
      if (!response.response_code) {
        return;
      }
      setSharingObjectsData(response.result);
      setSharingObjects([...sharingObjects, ...response.result.elements]);
    }
  };

  const [fetchEvents, isEventsLoading, eventsError] = useFetching(
    getUserInvolvedEvents
  );
  const [fetchProjects, isProjectsLoading, projectsError] = useFetching(
    getUserInvolvedProjects
  );
  const [fetchSharingObjects, isSharingObjectsLoading, sharingObjectsError] =
    useFetching(getUserSharingObjects);

  // useEffect(() => {
  // fetchEvents();
  //   fetchProjects();
  // }, []);

  useEffect(() => {
    // console.log(eventPage);
    fetchEvents(eventPage);
  }, [eventPage]);

  useEffect(() => {
    // console.log(sharingObjects);
  }, [sharingObjects]);

  useEffect(() => {
    // console.log(projectPage);
    fetchProjects(projectPage);
  }, [projectPage]);

  useEffect(() => {
    // console.log(projectPage);
    fetchSharingObjects(sharingObjectsPage);
  }, [sharingObjectsPage]);

  useObserver(
    lastEventElement,
    involvedEventsData && eventPage < involvedEventsData.total_pages,
    isEventsLoading,
    () => {
      setEventPage(eventPage + 1);
    }
  );

  useObserver(
    lastProjectElement,
    involvedProjectsData && projectPage < involvedProjectsData.total_pages,
    isProjectsLoading,
    () => {
      setProjectPage(projectPage + 1);
    }
  );

  // useObserver(
  //   lastSharingObjectsElement,
  //   sharingObjectsData && sharingObjectsPage < sharingObjectsData.total_pages,
  //   isSharingObjectsLoading,
  //   () => {
  //     setSharingObjectsPage(sharingObjectsPage + 1);
  //   }
  // );

  return (
    // <div className="card animation-appear">
    // <div className="card-content">
    <div className="row nm">
      <div className="col s12">
        {isSharingObjectsNeeded ? (
          <Tabs className="tabs-fixed-width" scope="tabs-22">
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title="События"
              className="mt30"
            >
              <EventTiles events={events} isLoading={isEventsLoading} />
              {isEventsLoading ? <CircleLoader /> : null}
              <div ref={lastEventElement}></div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title="Проекты"
              className="mt30"
            >
              {/* <ProjectList
                  projectsData={involvedProjectsData}
                  onSurface={true}
                  oneCol={false}
                /> */}
              <ProjectTiles projects={projects} isLoading={isProjectsLoading} />
              {isProjectsLoading ? <CircleLoader /> : null}
              <div ref={lastProjectElement}></div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title="Совместный доступ"
              className="mt30"
            >
              {/* <ProjectList
                  projectsData={involvedProjectsData}
                  onSurface={true}
                  oneCol={false}
                /> */}
              <EventTiles
                events={sharingObjects}
                isLoading={isSharingObjectsLoading}
              />
              {isSharingObjectsLoading ? <CircleLoader /> : null}
              {/* <div ref={lastSharingObjectsElement}></div> */}
            </Tab>
          </Tabs>
        ) : (
          <Tabs className="tabs-fixed-width" scope="tabs-22">
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title="События"
              className="mt30"
            >
              <EventTiles events={events} isLoading={isEventsLoading} />
              {isEventsLoading ? <CircleLoader /> : null}
              <div ref={lastEventElement}></div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title="Проекты"
              className="mt30"
            >
              {/* <ProjectList
                  projectsData={involvedProjectsData}
                  onSurface={true}
                  oneCol={false}
                /> */}
              <ProjectTiles projects={projects} isLoading={isProjectsLoading} />
              {isProjectsLoading ? <CircleLoader /> : null}
              <div ref={lastProjectElement}></div>
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default UserFlow;
