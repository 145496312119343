import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import RequestService from "../api/RequestService";
import NoAvatar from "../images/no-avatar.png";
import { abbrNum, copyTextToClipboard, formatNumber } from "../utils/service";
import { Button, Modal } from "react-materialize";
import UserFlow from "../components/UI/Users/UserFlow/UserFlow";
import SquareIconButton from "../components/UI/Button/SquareIconButton/SquareIconButton";
import { useFetching } from "../hooks/useFetching";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import ButtonPanel from "../components/UI/Button/ButtonPanel/ButtonPanel";
import { AuthContext } from "../context";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import Verified from "../components/UI/Verified/Verified";
import NewComplaint from "../components/UI/Complaints/NewComplaint/NewComplaint";
import { QRCodeCanvas } from "qrcode.react";
import IconLogoMono from "../images/icon-logo-mono.png";
import { Tooltip } from "react-tooltip";
import useConfirm from "../context/ConfirmDialog";

const User = () => {
  const params = useParams();
  const [userData, setUserData] = useState(null);
  const [userError, setUserError] = useState(null);
  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    fetchUserData();
  }, []);

  const getUser = async () => {
    const response = (await RequestService.getUser(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setUserError(response.message);
      return;
    }
    setUserData(response.result);
  };

  const [fetchUserData, isUserDataLoading, isUserDataError] =
    useFetching(getUser);

  const addBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.addBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchUserData();
  };

  const removeBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.removeBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchUserData();
  };

  const followUser = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.followUser(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchUserData();
  };

  const unfollowUser = async () => {
    // if (
    //   !window.confirm("Вы действительно хотите отписаться от пользователя?")
    // ) {
    const choice = await confirm({
      description: "Вы действительно хотите отписаться от пользователя?",
    });
    if (!choice) {
      return;
    }
    setFullScreenLoading(true);
    const response = (await RequestService.unfollowUser(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchUserData();
  };

  return (
    // <div style={{ paddingLeft: "220px", paddingTop: "35px" }}>
    //   <div className="container">
    //     {isUserDataError ? (
    //       <div
    //         className="row"
    //         style={{
    //           height: "100vh",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div className="col s12 center-align">
    //           <div className="xl500">isUserDataError</div>
    //         </div>
    //       </div>
    //     ) : null}
    //     {isUserDataLoading ? (
    //       <div
    //         className="row"
    //         style={{
    //           height: "100vh",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div className="col s12 center-align">
    //           <CircleLoader />
    //         </div>
    //       </div>
    //     ) : userData ? (
    //       <div className="row">
    //         <div className="col s12">
    //           <div className="card animation-appear">
    //             <div className="card-content">
    <RegularPage error={userError} isDataExist={userData}>
      {userData ? (
        <div>
          <div className="row">
            <div className="col s12">
              <button
                className="btn flat-btn iq-btn pastel-btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="material-icons-round left">arrow_back_ios</i>{" "}
                Назад
              </button>
              {userData.is_current_user ? (
                <button
                  className="btn btn-flat pastel-btn right modal-trigger"
                  href="#modal-qr"
                >
                  <i className="material-icons-round">qr_code_2</i>
                </button>
              ) : null}
              <Modal
                actions={[
                  <Button flat modal="close" node="button" className="iq-btn">
                    Закрыть
                  </Button>,
                ]}
                bottomSheet={false}
                fixedFooter
                header={"QR-код на ваш профиль"}
                id="modal-qr"
                open={false}
                options={{
                  dismissible: true,
                  endingTop: "10%",
                  inDuration: 250,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  opacity: 0.5,
                  outDuration: 250,
                  preventScrolling: true,
                  startingTop: "4%",
                }}
              >
                <div className="row">
                  <div className="col s12 center-align mt15 mb15 secondary-color">
                    QR-код содержит ссылку на ваш профиль Nott.one
                  </div>
                  <div className="col s12 center-align">
                    <QRCodeCanvas
                      value={
                        window.location.origin + "/users/" + userData.alias
                      }
                      fgColor="#030303"
                      bgColor={"transparent"}
                      size={256}
                      level={"M"}
                      style={{ height: "auto", maxWidth: "70%", width: "100%" }}
                      className="image-theme-filter mt10 mb10 hide-on-large-only"
                      imageSettings={{
                        src: IconLogoMono,
                        height: 50,
                        width: 50,
                        excavate: true,
                      }}
                    />
                    <QRCodeCanvas
                      value={
                        window.location.origin + "/users/" + userData.alias
                      }
                      fgColor="#030303"
                      bgColor={"transparent"}
                      size={256}
                      level={"M"}
                      style={{ height: "auto", maxWidth: "30%", width: "100%" }}
                      className="image-theme-filter mt10 mb10 hide-on-med-and-down"
                      imageSettings={{
                        src: IconLogoMono,
                        height: 50,
                        width: 50,
                        excavate: true,
                      }}
                    />
                  </div>
                </div>
              </Modal>
              <button
                className="btn btn-flat pastel-btn right"
                style={
                  userData.is_current_user ? { marginRight: "10px" } : null
                }
                onClick={() => {
                  copyTextToClipboard(
                    window.location.origin + "/users/" + userData.alias,
                    "Ссылка на пользователя скопирована!"
                  );
                }}
              >
                <i className="material-icons-round">ios_share</i>
              </button>
            </div>
          </div>

          {userData.is_banned == 1 && userData.is_current_user == 1 ? (
            <div className="row">
              <div className="col s12">
                <div className="card alert-message">
                  <div className="card-content">
                    <div className="row nm">
                      <div className="col s12">
                        Вы были заблокированы на основании поступающих от
                        пользователей жалоб. Теперь вы не можете отправлять
                        сообщения другим пользователям, участвовать в
                        мероприятиях и проектах. Помимо этого, мы ограничили
                        доступ к вашему профилю и приостановили возможность
                        участвовать в ваших мероприятиях и проектах другим
                        пользователям. Если вы считаете, что были заблокированы
                        по ошибке, сообщите нам любым удобным способом.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row mb25">
            <div className="col s12 l6">
              <div className="row mt15">
                <div
                  className="col s12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="circular-image pastel-shadow"
                    style={{
                      width: "120px",
                      height: "120px",
                      position: "relative",
                    }}
                  >
                    <div
                      className={
                        userData.avatar_img_url
                          ? "circular-image"
                          : "circular-image image-theme-filter"
                      }
                      style={{
                        backgroundImage:
                          "url(" +
                          (userData.avatar_img_url
                            ? userData.avatar_img_url
                            : NoAvatar) +
                          ")",
                        width: "120px",
                        height: "120px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12 xl500 center-align">
                  {userData.first_name + " " + userData.last_name}
                  {userData.is_verified == 1 ? <Verified ml={5} /> : null}
                </div>

                <div className="col s12 center-align">@{userData.alias}</div>

                <div className="col s12 center-align accent-color xl500 mt15">
                  <i
                    className="material-icons accent-color hand-cursor"
                    style={{
                      position: "absolute",
                      marginLeft: "-30px",
                      marginTop: "2px",
                    }}
                  >
                    star
                  </i>
                  <span className="hand-cursor" data-tooltip-id="user-rating">
                    {formatNumber(userData.rating.toFixed(2))}
                  </span>
                  <Tooltip
                    id={"user-rating"}
                    style={{
                      fontSize: "13px",
                      backgroundColor: "var(--accent-color)",
                    }}
                    place={"top"}
                  >
                    <div>Рейтинг формируется из оценок,</div>
                    <div style={{ marginTop: "-10px" }}>
                      которые ставят организаторы событий
                    </div>
                  </Tooltip>
                </div>

                {userData.interests.length > 0 ? (
                  <div className="col s12 mt20">
                    <div className="row nm center-align">
                      <div className="col s12">
                        {userData.interests.map((interest) => (
                          <div
                            className="chip hand-cursor"
                            key={interest.name}
                            onClick={() => {
                              let urlSearchParams = new URLSearchParams();
                              urlSearchParams.append(
                                "interests[]",
                                interest.interest_id
                              );
                              navigate(
                                "/events/flow?" + urlSearchParams.toString()
                              );
                            }}
                          >
                            {"#" + interest.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col s12 center-align mt15">
                  <i
                    className="material-icons-outlined"
                    style={{ position: "absolute" }}
                  >
                    place
                  </i>
                  <span style={{ marginLeft: "30px" }}>
                    {userData.location ? userData.location : "Неизвестно"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col l3 hide-on-med-and-down"></div>
                <div className="col s6 l3 center-align mb15">
                  <div className="row nm">
                    {userData.is_current_user != 1 ? (
                      <div className="col s12 xxl500">
                        {abbrNum(userData.followers_count, 1)}
                      </div>
                    ) : (
                      <Link to="/followers">
                        <div
                          className="col s12 xxl500 accent-color"
                          style={{ cursor: "pointer" }}
                        >
                          {abbrNum(userData.followers_count, 1)}
                          {userData.new_follow_requests_count > 0 ? (
                            <span
                              className="accent-counter xs300 error-bg"
                              style={{
                                // position: "absolute",
                                // marginLeft: "90px",
                                // marginTop: "-10px",
                                verticalAlign: "top",
                              }}
                            >
                              {"+" + userData.new_follow_requests_count}
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    )}

                    <div className="col s12 s300">подписчиков</div>
                  </div>
                </div>
                <div className="col s6 l3 center-align">
                  <div className="row nm">
                    {userData.is_current_user != 1 ? (
                      <div className="col s12 xxl500">
                        {abbrNum(userData.subscriptions_count, 1)}
                      </div>
                    ) : (
                      <Link to="/subscriptions">
                        <div
                          className="col s12 xxl500 accent-color"
                          style={{ cursor: "pointer" }}
                        >
                          {abbrNum(userData.subscriptions_count, 1)}
                        </div>
                      </Link>
                    )}

                    <div className="col s12 s300">подписок</div>
                  </div>
                </div>
                <div className="col l3 hide-on-med-and-down"></div>
              </div>

              {userData.follow > -1 ? (
                <div className="row mb40">
                  <div className="col s12 center-align">
                    <Button
                      flat
                      className={
                        userData.follow === null
                          ? "iq-btn"
                          : "pastel-btn iq-btn"
                      }
                      onClick={() => {
                        if (userData.follow === null) {
                          followUser();
                        } else {
                          unfollowUser();
                        }
                      }}
                    >
                      {userData.follow === null
                        ? userData.is_private == 1
                          ? "Заявка на подписку"
                          : "Подписаться"
                        : userData.follow === 0
                        ? "Заявка отправлена"
                        : "Вы подписаны"}
                    </Button>
                  </div>
                </div>
              ) : null}

              {/* <div className="row nm mt35" style={{ height: "110px" }}>
                        
                      </div> */}
            </div>
            <div className="col s12 l6">
              <div className="row nm">
                <div className="col s12">
                  <div className="card bg">
                    <div className="card-content">
                      <div className="row mb35">
                        <div className="col s12 xl500 mb15">Контакты</div>
                        <div className="col s12">
                          {userData.contact_info
                            ? userData.contact_info
                            : "Информации нет"}
                        </div>
                      </div>
                      <div className="row mb40">
                        <div className="col s12 xl500 mb15">О себе</div>
                        <div className="col s12">
                          {userData.about_info
                            ? userData.about_info
                            : "Информации нет"}
                        </div>
                      </div>
                      <div className="row nm">
                        {userData.follow == -1 ? (
                          <div className="col s12">
                            <ButtonPanel
                              btnsArr={[
                                {
                                  icon_name: "edit",
                                  title: "Изменить",
                                  on_click: function () {
                                    navigate("/profile/edit");
                                  },
                                },
                                {
                                  icon_name: "settings",
                                  title: "Настройки",
                                  on_click: function () {
                                    navigate("/settings");
                                  },
                                },
                                {
                                  icon_name: "groups",
                                  title: "Рефералы",
                                  on_click: function () {
                                    navigate("/referral");
                                  },
                                },
                              ]}
                              panelColor={true}
                            />
                          </div>
                        ) : (
                          <div className="col s12">
                            <ButtonPanel
                              btnsArr={[
                                {
                                  icon_name: "send",
                                  title: "Сообщение",
                                  on_click: function () {
                                    navigate("/messages/" + userData.alias);
                                  },
                                },
                                {
                                  icon_name: userData.is_bookmarked
                                    ? "bookmark_added"
                                    : "bookmark_border",
                                  title: userData.is_bookmarked
                                    ? "В закладках"
                                    : "Закладка",
                                  on_click: function () {
                                    if (userData.is_bookmarked) {
                                      removeBookmark();
                                    } else {
                                      addBookmark();
                                    }
                                  },
                                },
                                {
                                  icon_name: "thumb_down_off_alt",
                                  title: "Жалоба",
                                  modal: {
                                    title: "Пожаловаться",
                                    content: <NewComplaint />,
                                  },
                                },
                              ]}
                              panelColor={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row nm">
            <div className="col s12"> */}
          {userData.is_private == 1 &&
          userData.is_current_user != 1 &&
          userData.follow != 1 ? (
            <div className="row nm center-align">
              <div className="col s12">
                <i className="material-icons" style={{ fontSize: "60px" }}>
                  lock
                </i>
              </div>
              <div className="col s12">
                У пользователя закрытый аккаунт. Только подписчики могут видеть
                больше информации.
              </div>
            </div>
          ) : (
            <UserFlow
              isSharingObjectsNeeded={userData.is_current_user == 1 ? 1 : 0}
            />
          )}
          {/* </div> */}
          {/* </div> */}
        </div>
      ) : null}
    </RegularPage>
    //       </div>
    //     ) : null}
    //   </div>
    // </div>
  );
};

export default User;
