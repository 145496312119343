import axios from "axios";
import { getCookie, sleep, SERVER_SITE } from "../utils/service";

export default class RequestService {
  static api = axios.create({
    withCredentials: true,
    baseURL: SERVER_SITE,
  });

  static async getRequest(path) {
    try {
      let params = [
        path,
        {
          headers: {
            "X-XSRF-Token": getCookie("nott_xsrf_token"),
          },
        },
      ];
      let response = await this.api.get(...params);

      if (!response.data.response_code) {
        // unauthorized
        if (response.data.status == 401) {
          response = await this.refreshTokens();
          response = await this.api.get(...params);
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async postRequest(path, data) {
    try {
      let params = [
        path,
        data,
        {
          headers: {
            "X-XSRF-Token": getCookie("nott_xsrf_token"),
          },
        },
      ];
      let response = await this.api.post(...params);
      // console.log(response.data);
      if (!response.data.response_code) {
        // unauthorized
        if (response.data.status == 401) {
          response = await this.refreshTokens();
          response = await this.api.post(...params);
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAllEvents(coordinates = null) {
    try {
      let path = "/events/getAll";
      if (
        coordinates &&
        Array.isArray(coordinates) &&
        coordinates[0] &&
        coordinates[1]
      ) {
        path =
          path + "?latitude=" + coordinates[0] + "&longitude=" + coordinates[1];
      }

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getEvents(coordinates = null, page = 1, filterParams = "") {
    try {
      let path =
        "/events/get?page=" + page + (filterParams ? "&" + filterParams : "");
      if (
        coordinates &&
        Array.isArray(coordinates) &&
        coordinates[0] &&
        coordinates[1]
      ) {
        path =
          path + "?latitude=" + coordinates[0] + "&longitude=" + coordinates[1];
      }

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getEvent(eventId) {
    try {
      let path = "/events/get?eventId=" + eventId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getUserEvents() {
    try {
      let path = "/user/events";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async createEvent(name, params) {
    try {
      let path = "/events/create" + (params ? "?" + params : "");
      let data = {
        event_name: name,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async unpublishEvent(eventId) {
    try {
      let path = "/events/unpublish";
      let data = {
        event_id: eventId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async publishEvent(eventId) {
    try {
      let path = "/events/publish";
      let data = {
        event_id: eventId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async publishProject(projectId) {
    try {
      let path = "/projects/publish";
      let data = {
        project_id: projectId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async unpublishProject(projectId) {
    try {
      let path = "/projects/unpublish";
      let data = {
        project_id: projectId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async removeEvent(eventId) {
    try {
      let path = "/events/remove";
      let data = {
        event_id: eventId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async cancelEvent(eventId) {
    try {
      let path = "/events/cancel";
      let data = {
        event_id: eventId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getPackage(packageId) {
    try {
      let path = "/packages/get?packageId=" + packageId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async removePackage(packageId) {
    try {
      let path = "/packages/remove";
      let data = {
        package_id: packageId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getMeetingParticipants(packageId) {
    try {
      let path = "/packages/getMeetingParticipants?packageId=" + packageId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async sendForm(data, path) {
    try {
      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async removeFromCloud(fileId) {
    try {
      let path = "/cloud/remove";
      let data = {
        file_id: fileId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async searchLocation(place) {
    try {
      let path = "/service/searchLocation?place=" + place;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getGeoDataByAddress(address) {
    try {
      let path = "/service/getGeoDataByAddress?address=" + address;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getCurrencies() {
    try {
      let path = "/service/getCurrencies";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getAgeLimits() {
    try {
      let path = "/service/getAgeLimits";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getTimezones() {
    try {
      let path = "/service/getTimezones";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async checkConnection() {
    try {
      let path = "/connect/check";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async auth(login, password) {
    try {
      let path = "/connect/auth";
      let data = {
        login: login,
        password: password,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async signup(email, promocode) {
    try {
      let path = "/connect/signup";
      let data = {
        email: email,
        promo_code: promocode,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async setReferrer(from) {
    try {
      let path = "/service/setReferrer";
      let data = {
        from: from,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async refreshTokens() {
    try {
      let response = await this.api.post("/connect/token");
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async logout() {
    try {
      let path = "/connect/logout";
      let data = {};

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getGeolocation() {
    try {
      let geolocation = await this.getBrowserGeolocation()
        .then(({ coords }) => {
          const { latitude, longitude } = coords;
          const position = [latitude, longitude];
          return position;
        })
        .catch(async (err) => {
          let path = "/service/getGeolocation";
          let position = await this.getRequest(path);

          return position.data.result;
        });

      return geolocation;
    } catch (error) {
      throw error;
    }
  }

  static getBrowserGeolocation() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
      });
    });
  }

  static async getProjects(coordinates = null, page = 1, filterParams = "") {
    try {
      let path =
        "/projects/get?page=" + page + (filterParams ? "&" + filterParams : "");
      if (
        coordinates &&
        Array.isArray(coordinates) &&
        coordinates[0] &&
        coordinates[1]
      ) {
        path =
          path + "&latitude=" + coordinates[0] + "&longitude=" + coordinates[1];
      }

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getProject(projectId) {
    try {
      let path = "/projects/get?projectId=" + projectId;

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getUserProjects() {
    try {
      let path = "/user/projects";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async removeProject(projectId) {
    try {
      let path = "/projects/remove";
      let data = {
        project_id: projectId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getUsers(coordinates = null, page = 1, filterParams = "") {
    try {
      let path =
        "/users/get?page=" + page + (filterParams ? "&" + filterParams : "");
      if (
        coordinates &&
        Array.isArray(coordinates) &&
        coordinates[0] &&
        coordinates[1]
      ) {
        path =
          path + "&latitude=" + coordinates[0] + "&longitude=" + coordinates[1];
      }

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getUser(userId) {
    try {
      let path = "/users/get?userId=" + userId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getInvolvedEvents(userId, page = 1) {
    try {
      let path = "/users/getInvolvedEvents?userId=" + userId + "&page=" + page;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getInvolvedProjects(userId, page = 1) {
    try {
      let path =
        "/users/getInvolvedProjects?userId=" + userId + "&page=" + page;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getSharingObjects(page = 1) {
    try {
      let path = "/user/sharingObjects?page=" + page;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getBookmarks(page = 1) {
    try {
      let path = "/user/bookmarks?page=" + page;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getInterests() {
    try {
      let path = "/interests/get";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getUserActiveSessions() {
    try {
      let path = "/user/sessions";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async resetSessions() {
    try {
      let path = "/user/resetSessions";
      let data = {};

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async resetSession(sessionId) {
    try {
      let path = "/user/resetSession";
      let data = {
        session_id: sessionId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async checkRecoveryData(email, key) {
    try {
      let path = "/profile/checkRecoveryData";
      let data = {
        email: email,
        key: key,
      };
      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async setAccountType(isPrivate) {
    try {
      let path = "/profile/setAccountType";
      let data = {
        is_private: isPrivate,
      };
      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async setNotifications(settings) {
    try {
      let path = "/profile/setNotifications";
      let data = { ...settings };
      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getComplaintReasons() {
    try {
      let path = "/complaints/getReasons";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async addBookmark(objectId) {
    try {
      let path = "/bookmarks/add";
      let data = {
        object_id: objectId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async removeBookmark(objectId) {
    try {
      let path = "/bookmarks/remove";
      let data = {
        object_id: objectId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async followUser(user) {
    try {
      let path = "/followers/follow";
      let data = {
        user: user,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async unfollowUser(user) {
    try {
      let path = "/followers/unfollow";
      let data = {
        user: user,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getFollowers(page = 1) {
    try {
      let path = "/followers/get?page=" + page;

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getFollowerRequests() {
    try {
      let path = "/followers/getRequests";

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async approveSubscription(user) {
    try {
      let path = "/followers/approve";
      let data = {
        user: user,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async rejectSubscription(user) {
    try {
      let path = "/followers/reject";
      let data = {
        user: user,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getSubscriptions(page = 1) {
    try {
      let path = "/subscriptions/get?page=" + page;

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getReferrals(page = 1) {
    try {
      let path = "/user/referrals?page=" + page;

      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getPartnerCodes() {
    try {
      let path = "/user/partnerCodes";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async removePartnerCode(code) {
    try {
      let path = "/referrals/removeCode";
      let data = {
        code: code,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async createPartnerCode(code, comment) {
    try {
      let path = "/referrals/createCode";
      let data = {
        code: code,
        comment: comment,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async removeSharingRole(objectId, user) {
    try {
      let path = "/events/unshare";
      let data = {
        object_id: objectId,
        user: user,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getFeedbackQuiz(objectId) {
    try {
      let path = "/feedback/getQuiz";
      let data = {
        object_id: objectId,
        ts: (new Date().getTime() / 1000).toFixed(0),
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async getChats() {
    try {
      let path = "/messages/chats";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getChatMessages(chatId, page = 1) {
    try {
      let path = "/messages/chat?id=" + chatId + "&page=" + page;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getTickets(objectId) {
    try {
      let path = "/tickets/get?object=" + objectId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getObjectBalance(objectId) {
    try {
      let path = "/tickets/balance?object=" + objectId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async getUserTickets() {
    try {
      let path = "/user/tickets";
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async checkTicket(ticketId) {
    try {
      let path = "/tickets/check?id=" + ticketId;
      return await this.getRequest(path);
    } catch (error) {
      throw error;
    }
  }

  static async checkUserIn(ticketId) {
    try {
      let path = "/tickets/checkIn";
      let data = {
        ticket_id: ticketId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async buyTicket(pack) {
    try {
      let path = "/tickets/buy";
      let data = {
        package: pack,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async cancelTicket(ticketId) {
    try {
      let path = "/tickets/cancel";
      let data = {
        order: ticketId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async rateParticipant(alias, rate, orderId) {
    try {
      let path = "/feedback/add";
      let data = {
        object_id: alias,
        rate: rate,
        order_id: orderId
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async goBroadcast(ticketId) {
    try {
      let path = "/tickets/join";
      let data = {
        order: ticketId,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }

  static async completeVkidVerify(link) {
    try {
      let path = "/user/vkidVerify";
      let data = {
        link: link,
      };

      return await this.postRequest(path, data);
    } catch (error) {
      throw error;
    }
  }
}
