import React from "react";
import { Button } from "react-materialize";
import { useNavigate } from "react-router-dom";
import NoAvatar from "../../../../images/no-avatar.png";
import Verified from "../../Verified/Verified";

const UserSmallCard = ({
  user,
  isPastel = false,
  actionBtnData1 = null,
  actionBtnData2 = null,
}) => {
  const navigate = useNavigate();

  return (
    <div className="row nm">
      <div className="col s12 mb25">
        <div
          className={
            isPastel ? "card pastel" : "card"
          }
        >
          <div className="card-content">
            <div className="row nm valign-wrapper">
              <div className="col s4">
                <div
                  className="circular-image pastel-shadow"
                  style={{
                    width: "70px",
                    height: "70px",
                    position: "relative",
                    margin: "auto",
                  }}
                >
                  <div
                    className={
                      user.avatar_img_url
                        ? "circular-image hand-cursor"
                        : "circular-image hand-cursor image-theme-filter"
                    }
                    style={{
                      backgroundImage:
                        "url(" +
                        (user.avatar_img_url ? user.avatar_img_url : NoAvatar) +
                        ")",
                      width: "70px",
                      height: "70px",
                    }}
                    onClick={() => {
                      navigate("/users/" + user.alias);
                    }}
                  ></div>
                </div>
                {user.is_verified == 1 ? <Verified ml={60} mt={-73} /> : null}
              </div>
              <div className="col s8">
                <div className="row nm">
                  <div
                    className="col s12 l500 hand-cursor"
                    onClick={() => {
                      navigate("/users/" + user.alias);
                    }}
                  >
                    {user.first_name + " " + user.last_name}
                  </div>
                  {user.location ? (
                    <div className="col s12 mt5">
                      {user.location.split(" ")[0]}
                    </div>
                  ) : null}
                </div>
                <div className="row nm">
                  {actionBtnData1 ? (
                    <div className="col s12 mt10">
                      <span
                        className="iq-link"
                        onClick={() => {
                          actionBtnData1.action(user.alias);
                        }}
                      >
                        {actionBtnData1.text}
                      </span>
                    </div>
                  ) : null}

                  {actionBtnData2 ? (
                    <div className="col s12 mt5">
                      <span
                        className="iq-link"
                        onClick={() => {
                          actionBtnData2.action(user.alias);
                        }}
                      >
                        {actionBtnData2.text}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSmallCard;
