import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import RequestService from "../api/RequestService";
import {
  copyTextToClipboard,
  formatNumber,
  toTimeZone,
} from "../utils/service";
import noImage from "../images/no-image.png";
import noAvatar from "../images/no-avatar.png";
import moment from "moment";
import PackageButton from "../components/UI/ParticipationPackage/PackageButton/PackageButton";
import { Modal, Button, Icon } from "react-materialize";
import PackageScreen from "../components/UI/ParticipationPackage/PackageScreen/PackageScreen";
import ButtonPanel from "../components/UI/Button/ButtonPanel/ButtonPanel";
import { AuthContext } from "../context";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import Verified from "../components/UI/Verified/Verified";
import NewComplaint from "../components/UI/Complaints/NewComplaint/NewComplaint";
import useConfirm from "../context/ConfirmDialog";

const Project = () => {
  const [projectData, setProjectData] = useState(null);
  const [projectError, setProjectError] = useState(null);
  const [chosenPackage, setChosenPackage] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getProject(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      setProjectError(response.message);
      return;
    }
    // const pp = response.result.participation_packages.sort(
    //   (a, b) => a.price - b.price
    // )[0];
    // const minPrice = pp ? pp.price : null;
    let participantsCount = 0;
    if (response.result.participation_packages) {
      response.result.participation_packages =
        response.result.participation_packages.filter((el) => !el.deleted_at);
      response.result.participation_packages.forEach((pack) => {
        participantsCount += pack.participants_count;
      });
    }
    response.result.participants_count = participantsCount;
    setProjectData(response.result);
  };

  const choosePackage = (packageData) => {
    setChosenPackage(packageData);
  };

  const removeProject = async () => {
    // if (window.confirm("Вы действительно хотите удалить проект?")) {
    const choice = await confirm({
      description: "Вы действительно хотите удалить проект?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removeProject(params.id)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description: "Проект удален",
        isAlert: true,
      });
      // alert("Проект удален");

      navigate("/profile");
    }
  };

  const publishProject = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.publishProject(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    await confirm({
      description: "Проект отправлен на модерацию",
      isAlert: true,
    });
    // alert("Проект отправлен на модерацию");

    getProject();
  };

  const unpublishProject = async () => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите снять проект с публикации? После этого понадобится повторная модерация."
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Вы действительно хотите снять проект с публикации? После этого понадобится повторная модерация.",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.unpublishProject(params.id)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description: "Проект снят с публикации",
        isAlert: true,
      });
      // alert("Проект снят с публикации");

      getProject();
    }
  };

  const removePackage = async (packageId) => {
    // console.log(packageId);
    // if (window.confirm("Вы действительно хотите удалить пакет участия?")) {
    const choice = await confirm({
      description: "Вы действительно хотите удалить пакет участия?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removePackage(packageId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description: "Пакет участия удален",
        isAlert: true,
      });
      // alert("Пакет участия удален");

      getProject();
    }
  };

  const addBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.addBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    getProject();
  };

  const removeBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.removeBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    getProject();
  };

  return (
    // <div style={{ paddingLeft: "220px", paddingTop: "35px" }}>
    //   <div className="container">
    //     {projectError ? (
    //       <div
    //         className="row"
    //         style={{
    //           height: "100vh",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div className="col s12 center-align">
    //           <div className="xl500">{projectError}</div>
    //         </div>
    //       </div>
    //     ) : projectData ? (
    <RegularPage
      error={projectError}
      isDataExist={projectData}
      hasBaseCard={false}
    >
      {projectData && projectData.interests ? (
        <div>
          <div className="row">
            <div className="col s12 l6 mb25">
              <div className="card animation-appear">
                <div className="card-content">
                  <div className="row">
                    <div className="col s12">
                      <button
                        className="btn flat-btn iq-btn pastel-btn"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <i className="material-icons-round left">
                          arrow_back_ios
                        </i>{" "}
                        Назад
                      </button>
                      <button
                        className="btn btn-flat pastel-btn right"
                        onClick={() => {
                          copyTextToClipboard(
                            window.location.href,
                            "Ссылка на проект скопирована!"
                          );
                        }}
                      >
                        <i className="material-icons-round">ios_share</i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 xl500">{projectData.name}</div>
                  </div>

                  {projectData.is_owner == 1 ? (
                    <div>
                      {!projectData.published_at ? (
                        <div className="row">
                          <div className="col s12">
                            <div className="card alert-message">
                              <div className="card-content">
                                <div className="row nm">
                                  <div className="col s12">
                                    Проект находится в режиме черновика, поэтому
                                    он пока не опубликован. После отправки на
                                    публикацию, проект пройдет модерацию.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="row">
                        <div className="col s12">
                          {!projectData.published_at ? (
                            <span
                              className="iq-link publish-event-btn"
                              onClick={publishProject}
                            >
                              отправить на публикацию
                            </span>
                          ) : (
                            <span
                              className="iq-link unpublish-event-btn"
                              onClick={unpublishProject}
                            >
                              cнять с публикации
                            </span>
                          )}{" "}
                          |{" "}
                          <span
                            className="iq-link remove-event-btn"
                            onClick={removeProject}
                          >
                            удалить проект
                          </span>
                        </div>
                      </div> */}

                      <div className="row">
                        <div
                          className={
                            projectData.status_data.status == 4 ||
                            projectData.is_banned == 1
                              ? "col s12 error"
                              : projectData.status_data.status == 3
                              ? "col s12 alert"
                              : projectData.status_data.status == 2
                              ? "col s12 success-color"
                              : "col s12"
                          }
                        >
                          Статус:{" "}
                          {projectData.is_banned == 1
                            ? "заблокирован за нарушение"
                            : projectData.status_data.status == 1
                            ? "черновик"
                            : projectData.status_data.status == 2
                            ? "опубликовано"
                            : projectData.status_data.status == 3
                            ? "на модерации"
                            : projectData.status_data.status == 4
                            ? "отклонен" +
                              (projectData.status_data.message
                                ? " (" + projectData.status_data.message + ")"
                                : "")
                            : "неизвестно"}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {projectData.is_owner == 1 ? (
                    // <div className="col s12 mt15">
                    //   <i
                    //     className="material-icons-round"
                    //     style={{ position: "absolute", marginTop: "-2px" }}
                    //   >
                    //     groups
                    //   </i>

                    //   <span
                    //     style={{ marginLeft: "30px" }}
                    //     className={projectData.is_owner ? "iq-link" : ""}
                    //     onClick={() => {
                    //       if (projectData.is_owner) {
                    //         navigate("/tickets/" + params.id);
                    //       }
                    //     }}
                    //   >
                    //     {projectData.participants_count} заявок в команду
                    //   </span>
                    // </div>
                    <div className="row">
                      <div className="col s12">
                        <div className="card pastel-btn">
                          <div className="card-content">
                            <div className="row nm">
                              <div className="col s12">
                                <Icon
                                  style={{ position: "absolute" }}
                                  className="accent-color"
                                >
                                  groups
                                </Icon>
                                <span
                                  className="iq-link"
                                  style={{
                                    marginLeft: "45px",
                                  }}
                                  onClick={() => {
                                    navigate("/tickets/" + params.id);
                                  }}
                                >
                                  {projectData.participants_count} заявок в
                                  команду
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row nm" style={{ height: "110px" }}>
                    <div className="col s12 mb20">
                      <ButtonPanel
                        btnsArr={[
                          projectData.is_owner == 1
                            ? {
                                icon_name: "edit",
                                title: "Изменить",
                                on_click: function () {
                                  navigate("/projects/edit/" + projectData.id);
                                },
                              }
                            : {
                                icon_name: projectData.is_bookmarked
                                  ? "bookmark_added"
                                  : "bookmark_border",
                                title: projectData.is_bookmarked
                                  ? "В закладках"
                                  : "Закладка",
                                on_click: function () {
                                  if (projectData.is_bookmarked) {
                                    removeBookmark();
                                  } else {
                                    addBookmark();
                                  }
                                },
                              },
                          projectData.is_owner == 0
                            ? {
                                icon_name: "send",
                                title: "Сообщение",
                                on_click: function () {
                                  let text = encodeURIComponent(
                                    "Здравствуйте! Пишу по поводу " +
                                      window.location.href +
                                      ". "
                                  );
                                  navigate(
                                    "/messages/" +
                                      projectData.owner_id +
                                      "?text=" +
                                      text
                                  );
                                },
                              }
                            : !projectData.published_at
                            ? {
                                icon_name: "visibility",
                                title: "Опубликовать",
                                on_click: function () {
                                  publishProject();
                                },
                              }
                            : {
                                icon_name: "visibility_off",
                                title: "Скрыть",
                                on_click: function () {
                                  unpublishProject();
                                },
                              },
                          projectData.is_owner == 0
                            ? {
                                icon_name: "thumb_down_off_alt",
                                title: "Жалоба",
                                modal: {
                                  title: "Пожаловаться",
                                  content: <NewComplaint />,
                                },
                              }
                            : {
                                icon_name: "delete_forever",
                                title: "Удалить",
                                on_click: function () {
                                  removeProject();
                                },
                              },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="row nm">
                    <div className="col s12">
                      <img
                        src={
                          projectData.cover_img_url
                            ? projectData.cover_img_url
                            : noImage
                        }
                        className={
                          !projectData.cover_img_url
                            ? "image-theme-filter"
                            : null
                        }
                        alt=""
                        style={{ width: "100%", borderRadius: "15px" }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {projectData.interests.length > 0 ? (
                      <div className="col s12 mt15">
                        {projectData.interests.map((interest) => (
                          <div
                            className="chip hand-cursor"
                            key={interest.name}
                            onClick={() => {
                              let urlSearchParams = new URLSearchParams();
                              urlSearchParams.append(
                                "interests[]",
                                interest.interest_id
                              );
                              navigate(
                                "/projects?" + urlSearchParams.toString()
                              );
                            }}
                          >
                            {"#" + interest.name}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {projectData.team_required ? (
                      <div>
                        <div className="col s12 mt15">
                          <i
                            className="material-icons-round"
                            style={{ position: "absolute", marginTop: "-2px" }}
                          >
                            groups
                          </i>

                          <span style={{ marginLeft: "30px" }}>
                            Собираем команду
                          </span>
                        </div>
                        <div className="col s12 mt10">
                          Кто нам нужен: {projectData.team_required}
                        </div>
                      </div>
                    ) : null}
                    {projectData.money_required ? (
                      <div>
                        <div className="col s12 mt15">
                          <i
                            className="material-icons-round"
                            style={{ position: "absolute", marginTop: "-2px" }}
                          >
                            payment
                          </i>

                          <span style={{ marginLeft: "30px" }}>
                            Ищем инвестиции
                          </span>
                        </div>
                        <div className="col s12 mt10">
                          Сумма:{" "}
                          {formatNumber(projectData.money_required, true) +
                            " " +
                            (projectData.currency_symbol
                              ? projectData.currency_symbol
                              : projectData.currency_code)}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row mb25">
                    <div className="col s12">
                      <div className="card pastel-btn">
                        <div className="card-content">
                          <div className="row nm">
                            <div className="col s12 l500 mb20">Организатор</div>
                            <Link to={"/users/" + projectData.owner_id}>
                              <div className="col s4 l3">
                                <div
                                  className="circular-image pastel-shadow"
                                  style={{
                                    width: "55px",
                                    height: "55px",
                                    position: "relative",
                                    margin: "auto",
                                  }}
                                >
                                  <div
                                    className={
                                      projectData.owner_avatar_url
                                        ? "circular-image"
                                        : "circular-image image-theme-filter"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        (projectData.owner_avatar_url
                                          ? projectData.owner_avatar_url
                                          : noAvatar) +
                                        ")",
                                      height: "55px",
                                      width: "55px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Link>

                            <div className="col s8 l9">
                              <Link to={"/users/" + projectData.owner_id}>
                                <div className="row nm">
                                  <div className="col s12 m500">
                                    {projectData.owner_name}
                                    {projectData.owner_is_verified == 1 ? (
                                      <Verified
                                        ml={5}
                                        mt={-4}
                                        themeColor={true}
                                      />
                                    ) : null}
                                  </div>
                                  <div className="col s12 mt5 accent-color">
                                    {"@" + projectData.owner_id}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt15">
                    <div className="col s12 xl500 hide-on-med-and-down">
                      Варианты участия
                    </div>
                    <div
                      className="col s12 xl500 hide-on-large-only"
                      style={{ paddingTop: "20px" }}
                    >
                      Варианты участия
                    </div>
                  </div>
                  <div className="row">
                    {projectData.participation_packages.length > 0 ? (
                      projectData.participation_packages.map(
                        (el, index, arr) => {
                          let packMargin = 20;
                          if (index === arr.length - 1) {
                            packMargin = 0;
                          }

                          return (
                            <PackageButton
                              pack={el}
                              key={el.name}
                              marginBottom={packMargin}
                              choosePackage={choosePackage}
                              hasControls={
                                projectData.is_owner == 1 ? true : false
                              }
                              editPackage={() => {
                                navigate(
                                  "/packages/edit?package=" +
                                    el.id +
                                    "&project=" +
                                    params.id
                                );
                              }}
                              removePackage={() => {
                                removePackage(el.id);
                              }}
                            />
                          );
                        }
                      )
                    ) : (
                      <div className="col s12 error-color center-align">
                        <div
                          className="error-message"
                          style={{ padding: "15px" }}
                        >
                          Пакетов участия пока нет
                        </div>
                      </div>
                    )}

                    {projectData.is_owner == 1 ? (
                      <div className="col s12 center-align mt25">
                        <button
                          className="btn iq-btn"
                          onClick={() => {
                            navigate("/packages/create?project=" + params.id);
                          }}
                        >
                          <i className="material-icons-outlined left">
                            add_circle
                          </i>
                          Добавить
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 l6">
              <div className="card animation-appear">
                <div className="card-content">
                  <div className="row">
                    <div className="col s12 xl500">Описание</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.description
                        ? projectData.description
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Целевая аудитория</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.audience
                        ? projectData.audience
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Уникальность продукта</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.uniqueness
                        ? projectData.uniqueness
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Емкость рынка</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.market_volume
                        ? projectData.market_volume
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Масштабируемость</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.scalability
                        ? projectData.scalability
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Точка безубыточности</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.breakeven_point
                        ? projectData.breakeven_point
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Доходность</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.profitability
                        ? projectData.profitability
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Этапы и сроки</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.stages
                        ? projectData.stages
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Текущее состояние</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.state
                        ? projectData.state
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Команда</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.team
                        ? projectData.team
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Риски</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.risks
                        ? projectData.risks
                        : "Информация не указана"}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 xl500">Дополнительно</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      {projectData.additionally
                        ? projectData.additionally
                        : "Информация не указана"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            actions={[
              <Button flat modal="close" node="button" className="iq-btn">
                Закрыть
              </Button>,
            ]}
            bottomSheet={false}
            fixedFooter
            header="Принять участие"
            id="modal-package"
            open={false}
            options={{
              dismissible: true,
              endingTop: "10%",
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: "4%",
            }}
            // root={[object HTMLBodyElement]}
            // trigger={<Button node="button">MODAL WITH FIXED FOOTER</Button>}
          >
            <PackageScreen pack={chosenPackage} />
          </Modal>
        </div>
      ) : null}
    </RegularPage>
    //   ) : (
    //     <div
    //       className="row"
    //       style={{
    //         height: "100vh",
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <div className="col s12 center-align">
    //         <CircleLoader />
    //       </div>
    //     </div>
    //   )}
    // </div>
    // </div>
  );
};

export default Project;
