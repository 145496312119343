import React from "react";
import EventItem from "./EventItem";

const EventTiles = ({
  events,
  isLoading,
  onSurface = true,
  hideOldAndNoDatetime = false,
}) => {
  const failure = (
    <div>
      <div className="row nm mt15">
        <div className="col s12 center-align">
          Информации нет или при загрузке произошла ошибка
        </div>
      </div>
    </div>
  );
  // console.log(events);
  if (!events && !isLoading) {
    return failure;
  }
  if (events && !isLoading) {
    if (events.length == 0) {
      return failure;
    }
  }

  let col1 = null;
  let col2 = null;
  let col3 = null;
  let commonCol = null;

  if (events) {
    let curCol = 1;
    let c1 = [];
    let c2 = [];
    let c3 = [];
    let cc = [];
    
    for (let i = 0; i < events.length; i++) {
      if (hideOldAndNoDatetime) {
        if (new Date(events[i].datetime).getTime() > new Date().getTime()) {
          let element = (
            <EventItem
              event={events[i]}
              key={events[i].id}
              onSurface={onSurface}
            />
          );
          switch (curCol) {
            case 1:
              c1.push(element);
              curCol++;
              break;

            case 2:
              c2.push(element);
              curCol++;
              break;

            case 3:
              c3.push(element);
              curCol = 1;
              break;

            default:
              break;
          }
          cc.push(element);
        }
      } else {
        let element = (
          <EventItem
            event={events[i]}
            key={events[i].id}
            onSurface={onSurface}
          />
        );
        switch (curCol) {
          case 1:
            c1.push(element);
            curCol++;
            break;

          case 2:
            c2.push(element);
            curCol++;
            break;

          case 3:
            c3.push(element);
            curCol = 1;
            break;

          default:
            break;
        }
        cc.push(element);
      }
    }

    if (cc.length == 0) {
      return failure;
    }

    col1 = <div className="col s12 l4 hide-on-med-and-down">{c1}</div>;
    col2 = <div className="col s12 l4 hide-on-med-and-down">{c2}</div>;
    col3 = <div className="col s12 l4 hide-on-med-and-down">{c3}</div>;
    commonCol = <div className="col s12 l4 hide-on-large-only">{cc}</div>;
  } else {
    return (
      <div className="row nm">
        <div className="col s12 center-align">Ошибка</div>
      </div>
    );
  }

  return (
    <div className="row nm">
      {col1}
      {col2}
      {col3}
      {commonCol}
      {/* {events.hasOwnProperty("elements")
        ? events.elements.map((elem) => (
            <EventItem
              event={elem}
              key={elem.id}
              setMapState={setMapState}
              mapState={mapState}
              onSurface={onSurface}
            />
          ))
        : null} */}
    </div>
  );
};

export default EventTiles;
