import React from "react";
import { Tooltip } from "react-tooltip";

const Verified = ({ mt = 0, ml = 0, themeColor = false, place = "top" }) => {
  let randomId = (Math.random() * 100000).toFixed(0);

  return (
    <span
      style={{
        backgroundColor: themeColor
          ? "var(--bg-color)"
          : "var(--surface-bg-color)",
        position: "absolute",
        marginTop: mt,
        marginLeft: ml,
        width: "27px",
        height: "27px",
        borderRadius: "50px",
      }}
    >
      <i
        className="material-icons accent-color verified-icon"
        style={{
          cursor: "pointer",
          marginLeft: "1.5px",
          marginTop: "1.5px",
          // position: "absolute",
        }}
        data-tooltip-id={randomId + "-tooltip"}
        data-tooltip-content="Профиль верифицирован"
      >
        verified
      </i>
      <Tooltip
        id={randomId + "-tooltip"}
        style={{ fontSize: "13px", backgroundColor: "var(--accent-color)" }}
        place={place}
      />
    </span>
  );
};

export default Verified;
