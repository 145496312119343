import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";
import * as VKID from "@vkid/sdk";
import { useSearchParamsArray } from "../hooks/useSearchParamsArray";

const SettingsVerification = () => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();
  const searchParamsArray = useSearchParamsArray();

  useEffect(() => {
    if (searchParamsArray.object.result == 1) {
      confirm({
        description: "Верификация аккаунта прошла успешно!",
        isAlert: true,
      });
    } else if (searchParamsArray.object.result == 0) {
      confirm({
        description:
          "Не удалось осуществить верификацию аккаунта или ранее вы уже проходили верификацию.",
        isAlert: true,
      });
    }
  }, []);

  useEffect(() => {
    VKID.Config.init({
      app: 52010578,
      codeChallenge: "llhsUeNbOvWtGlcDkmCJil9oONOUE_dmLUh_egkkjNk",
      redirectUrl: window.nott.info.vkid_redirect_url,
      mode: VKID.ConfigAuthMode.Redirect,
    });

    let oneTap = new VKID.OneTap();
    let container = document.getElementById("VkIdSdkOneTap");

    if (container) {
      oneTap
        .render({
          container: container,
          scheme: VKID.Scheme.LIGHT,
          lang: VKID.Languages.RUS,
        })
        .on(VKID.WidgetEvents.ERROR, vkidHandleError);
    }
  }, []);

  const vkidHandleError = (e) => {
    console.log(e);
  };

  return (
    <RegularPage
      isDataExist={1}
      hasBaseCard={true}
      header={"Верификация"}
      isPrimaryPage={false}
      backText={"Назад"}
      backPath={"/settings"}
      hasAppearanceAnimation={true}
    >
      <div className="row mt25">
        <div className="col s12 l500">
          <i
            className="material-icons-outlined accent-color"
            style={{ position: "absolute", marginTop: "3px" }}
          >
            verified
          </i>
          <span style={{ marginLeft: "35px" }}>Верификация аккаунта</span>
        </div>
        <div className="col s12 mt10 secondary-color">
          Если вы верифицируете свой аккаунт, то напротив вашего имени появится
          специальная галочка, сигнализирующая о том, что профиль
          подтвержденный. К подтвержденным профилям у пользователей больше
          доверия. Есть два способа подтверждения профиля:
        </div>
        <div className="col s12 mt10 secondary-color">
          1. Написать письмо нам на{" "}
          <span className="accent-color">{window.nott.info.support_email}</span>{" "}
          с почтового ящика, на который зарегистрирован ваш аккаунт Nott.one, с
          просьбой верифицировать ваш аккаунт. К письму необходимо приложить
          ваше селфи с открытым паспортом на развороте страниц 2-3 (разворот с
          фото и подписью). Паспорт необходимо держать рядом с вашим лицом. Все
          данные, кроме ваших ФИО и даты рождения можно замазать в графическом
          редакторе. Фотография должна быть в хорошем качестве.
        </div>
        <div className="col s12 mt10 secondary-color">
          2. Подтвердить ваш аккаунт через VK, нажав на кнопку ниже. Однако
          стоит учитывать тот факт, что при данном способе подтверждения
          аккаунта, необходимо полное соответствие вашего имени и фамилии в
          Nott.one с теми, что указаны в вашем профиле VK. Ваш аккаунт VK будет
          прикреплен к профилю Nott.one, поэтому тот же аккаунт VK уже не
          получится прикрепить к другому профилю Nott.one.
        </div>
        <div className="col s4"></div>
        <div className="col s12 l4 mt25">
          <div id="VkIdSdkOneTap"></div>
        </div>
        <div className="col s4"></div>
      </div>
    </RegularPage>
  );
};

export default SettingsVerification;
